<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Total tournament wins</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="getWinningTeamsHurling" v-model="overallTeamSelections"
                                        @change="overallTeamSelectionsChanged" multiple placeholder="All" clearable
                                        label="Teams" item-text="name" return-object outlined hide-details hide-no-data>
                                        <template #selection="{ item }">
                                            <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallTeamSelections[0].name">{{
                                                overallTeamSelections.length }} teams</span>
                                        </template>
                                    </v-select>
                                    <v-combobox v-else :items="getWinningTeamsHurling" v-model="overallTeamSelections"
                                        @change="overallTeamSelectionsChanged" multiple placeholder="All" clearable
                                        label="Teams" item-text="name" return-object outlined hide-details hide-no-data>
                                        <template #selection="{ item }">
                                            <span v-if="overallTeamSelections.length === 1">{{ item.name }}</span>
                                            <span v-else-if="item.name == overallTeamSelections[0].name">{{
                                                overallTeamSelections.length }} teams</span>
                                        </template>
                                    </v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearFromFilters"
                                        v-model="overallTotalsYearFromFilter"
                                        @change="overallTotalsYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearToFilters" v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearToFilters" v-model="overallTotalsYearToFilter"
                                        @change="overallTotalsYearToFilterChanged" label="Year to" outlined hide-details
                                        hide-no-data></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <div id="overall-table">
                            <v-data-table :headers="activeHeaders" :items="relevantWinners"
                                :items-per-page="itemsPerPage" @pagination="onPagination" sort-by="AllIrelandWins"
                                sort-desc class="elevation-1" mobile-breakpoint="0">

                                <template v-slot:item.index="{ index }">
                                    {{ (currentPage - 1) * itemsPerPage + index + 1 }}
                                </template>

                                <template v-slot:item.team="{ item }">
                                    <span style="display: flex;" @click="gotToTeamHonours(item.team.id)"
                                        class="cursor-pointer">
                                        <v-img :src="`/assets/gaa/teams/icons/${item.team.code}.WEBP`" contain
                                            max-width="30" class="mr-2 img_border"></v-img>
                                        {{ item.team.name }}
                                    </span>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    name: 'RollOfHonour',
    components: {
        StatsCard
    },
    async mounted() {
        await this.fetchWinners(this.$router.currentRoute.meta.sport.toUpperCase());
        this.fetchWinnersForSport(this.$router.currentRoute.meta.sport.toUpperCase());
        this.loading = false;
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            currentPage: 1,
            itemsPerPage: 10,
            overallTeamSelections: [],
            overallWinnerYearToFilter: 'All',
            overallTotalsYearFromFilter: 'All',
            overallTotalsYearToFilter: 'All',
            overallTableHeaders: [
                {
                    text: '',
                    sortable: false,
                    value: 'index'
                },
                {
                    text: 'Team',
                    sortable: true,
                    value: 'team'
                },
                { text: 'Tier 2-5 Runner Up', value: 'tier25RunnerUp', sortable: true },
                { text: 'Tier 2-5 Win', value: 'tier25Wins', sortable: true },
                { text: 'Provincial Runner Up', value: 'provincialRunnerUp', sortable: true },
                { text: 'Provincial Win', value: 'provincialWins', sortable: true },
                { text: 'All Ireland Runner Up', value: 'AllIrelandRunnerUp', sortable: true },
                { text: 'All Ireland Win', value: 'AllIrelandWins', sortable: true }
            ],
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getWinners']),
        relevantWinners() {
            return this.getWinningResults.filter(it => it.team.id != -1);
        },
        activeHeaders() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return this.overallTableHeaders
            } else {
                const filteredHeaders = this.overallTableHeaders.filter(it =>
                    it.value !== 'index' &&
                    it.value !== 'provincialRunnerUp' &&
                    it.value !== 'tier25RunnerUp' &&
                    it.value !== 'AllIrelandRunnerUp'
                );

                // Separate the first header after filtering
                const [firstHeader, ...restHeaders] = filteredHeaders;

                // Reverse the remaining headers
                const finalHeaders = [firstHeader, ...restHeaders.reverse()];

                return finalHeaders;

            }
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => a - b));
            return years;
        },

    },
    methods: {
        ...mapActions(['fetchWinners', 'fetchWinnersForSport', 'updateOverallTeamSelections', 'updateOverallTotalsYearFromFilter', 'updateOverallTotalsYearToFilter']),
        overallTeamSelectionsChanged() {
            this.updateOverallTeamSelections(this.overallTeamSelections);
            if (this.overallTeamSelections.length == 1) {
                this.$router.push({
                    path: this.$route.path, // Keep the same path
                    query: {
                        ...this.$route.query,
                        team: this.overallTeamSelections[0].id
                    }
                });
            } else {
                this.$router.push({
                    path: this.$route.path, // Keep the same path
                    query: {
                        ...this.$route.query,
                        team: null
                    }
                });
            }
        },
        overallTotalsYearFromFilterChanged() {
            this.updateOverallTotalsYearFromFilter(this.overallTotalsYearFromFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallTotalsYearFromFilter
                }
            });
        },
        overallTotalsYearToFilterChanged() {
            this.updateOverallTotalsYearToFilter(this.overallTotalsYearToFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallTotalsYearToFilter
                }
            });
        },
        gotToTeamHonours(teamId) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/honours/5/${teamId}`)
        },
        onPagination(pagination) {
            this.currentPage = pagination.page;
            this.itemsPerPage = pagination.itemsPerPage;
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.team) {
                this.overallTeamSelections.push(this.getWinningTeamsHurling.find(it => it && it.id == this.$router.currentRoute.query.team));
                this.overallTeamSelectionsChanged();
            }
            if (this.$router.currentRoute.query.from) {
                this.overallTotalsYearFromFilter = this.$router.currentRoute.query.from;
                this.overallTotalsYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallTotalsYearToFilter = this.$router.currentRoute.query.to;
                this.overallTotalsYearToFilterChanged();
            }
        }
    }
}
</script>

<style></style>