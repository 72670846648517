<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '4' : '12'" class="d-flex align-center">
                                <div style="word-break: keep-all;">
                                    <h5>Compartive tournament wins</h5>
                                </div>
                            </v-col>
                            <v-spacer />
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearFromFilters"
                                        v-model="overallWinnerYearFromFilter"
                                        @change="overallWinnerYearFromFilterChanged" label="Year from" outlined
                                        hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallWinnerYearToFilters"
                                        v-model="overallWinnerYearToFilter" @change="overallWinnerYearToFilterChanged"
                                        label="Year to" outlined hide-details hide-no-data></v-select>
                                    <v-combobox v-else :items="overallWinnerYearToFilters"
                                        v-model="overallWinnerYearToFilter" @change="overallWinnerYearToFilterChanged"
                                        label="Year to" outlined hide-details hide-no-data></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '2' : '12'">
                                <div>
                                    <v-select v-if="$vuetify.breakpoint.xsOnly" :items="overallCompetitionOptions"
                                        v-model="overallCompetitionFilter" @change="overallCompetitionFilterChanged"
                                        item-text="label" item-value="value" label="Competition" outlined hide-details
                                        hide-no-data></v-select>
                                    <v-combobox v-else :items="overallCompetitionOptions"
                                        v-model="overallCompetitionFilter" @change="overallCompetitionFilterChanged"
                                        item-text="label" item-value="value" label="Competition" outlined hide-details
                                        hide-no-data></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <div id="bar-chart-comparison" class="chartBackground">
                            <apexchart type="bar" :options="barChartOptions" height="350" :series="[{
                                name: 'Count',
                                data: overallPieChartTotals
                            }]"></apexchart>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import StatsCard from '@/components/card/statsCard/StatsCard'

export default {
    name: 'RollOfHonour',
    components: {
        StatsCard
    },
    async mounted() {
        await this.fetchWinners(this.$router.currentRoute.meta.sport.toUpperCase());
        this.loading = false;
        this.updateOverallCompetitionFilter('All Ireland');
        this.populateQueryParams();
    },
    data() {
        return {
            loading: true,
            overallCompetitionFilter: 'All Ireland',
            overallTeamSelections: [],
            overallWinnerYearFromFilter: 'All',
            overallWinnerYearToFilter: 'All',
            overallCompetitionFilter: 'All Ireland',
        }
    },
    computed: {
        ...mapGetters(['getWinningResults', 'getWinningTeamsHurling', 'getWinners']),
        barChartOptions() {
            return {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        distributed: true, // Enable distributed mode for column charts
                        horizontal: false
                    }
                },
                colors: this.overallPieChartColours,
                xaxis: {
                    categories: this.overallPieChartLabels,
                }
            }
        },
        overallPieChartLabels() {
            let labels = [];
            for (const key in this.$store.getters['getCompetitionWinnerTotals']) {
                if (this.$store.getters['getWinnersNames'][key]) {
                    labels.push(this.$store.getters['getWinnersNames'][key]);
                }
            }

            return labels;
        },
        overallPieChartColours() {
            let colours = [];
            for (const key in this.$store.getters['getCompetitionWinnerTotals']) {
                if (this.$store.getters['getWinnersColours'][key]) {
                    colours.push(this.$store.getters['getWinnersColours'][key])
                }
            }
            if (colours.length == 0) {
                colours.push("#808080")
            }
            return colours;
        },
        overallPieChartImages() {
            let images = [];
            for (const key in this.$store.getters['getCompetitionWinnerTotals']) {
                if (this.$store.getters['getWinnersCodes'][key]) {
                    images.push(`/assets/gaa/teams/icons/${this.$store.getters['getWinnersCodes'][key]}.WEBP`)
                } else {
                    images.push('/assets/gaa/teams/chart_icons/draw.jpg')
                }
            }
            return images;
        },
        overallPieChartTotals() {
            let resultTotals = this.$store.getters['getCompetitionWinnerTotals'];
            let charTotals = [];
            for (const key in resultTotals) {
                charTotals.push(resultTotals[key]);
            }
            if (charTotals.length == 0) {
                charTotals.push(1);
            }
            return charTotals;
        },
        overallCompetitionOptions() {
            let stages = [];
            stages.push(...this.$store.getters['getWinners'].map(it => it.tournament));
            return stages;
        },
        overallWinnerYearToFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => b - a));
            return years;
        },
        overallWinnerYearFromFilters() {
            let years = ['All'];
            years.push(...this.getWinners.map(it => it.year).sort((a, b) => a - b));
            return years;
        },
    },
    methods: {
        ...mapActions(['fetchWinners', 'updateOverallTeamSelections', 'updateOverallWinnerYearFromFilter', 'updateOverallWinnerYearToFilter', 'updateOverallCompetitionFilter']),
        overallTeamSelectionsChanged() {
            this.updateOverallTeamSelections(this.overallTeamSelections);
        },
        overallCompetitionFilterChanged() {
            this.updateOverallCompetitionFilter(this.overallCompetitionFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    competition: this.overallCompetitionFilter
                }
            });
        },
        overallWinnerYearFromFilterChanged() {
            this.updateOverallWinnerYearFromFilter(this.overallWinnerYearFromFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    from: this.overallWinnerYearFromFilter
                }
            });
        },
        overallWinnerYearToFilterChanged() {
            this.updateOverallWinnerYearToFilter(this.overallWinnerYearToFilter);
            this.$router.push({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    to: this.overallWinnerYearToFilter
                }
            });
        },
        populateQueryParams() {
            if (this.$router.currentRoute.query.competition) {
                let queryString = this.$router.currentRoute.query.competition;
                this.overallCompetitionFilter = queryString.split(' ') // Split the string into an array of words
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                    .join(' ');
                this.overallCompetitionFilterChanged();
            }
            if (this.$router.currentRoute.query.from) {
                this.overallWinnerYearFromFilter = this.$router.currentRoute.query.from;
                this.overallWinnerYearFromFilterChanged();
            }
            if (this.$router.currentRoute.query.to) {
                this.overallWinnerYearToFilter = this.$router.currentRoute.query.to;
                this.overallWinnerYearToFilterChanged();
            }
        }
    }
}
</script>

<style>
.chartBackground {
    background-image: url('/assets/logo/ktg_green_fade.svg');
    background-size: contain;
    background-position: center;
}
</style>